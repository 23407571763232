/* eslint-disable import/no-extraneous-dependencies */
import mixpanel from 'mixpanel-browser';

export const init = () => {
  const enableDebugMode = process.env.NODE_ENV !== 'production';

  const getMeta = (name) => {
    const metaTag = document.querySelector(`meta[name=mixpanel-${name}]`);
    if (metaTag != null) {
      return metaTag.getAttribute('content');
    }
    return null;
  };

  const getDefaultProps = () => {
    const controller = getMeta('controller');
    const action = getMeta('action');
    const loc = window.location;

    return {
      is_authenticated: Dribbble?.JsConfig?.user?.isLoggedIn || Dribbble?.user?.isLoggedIn, // check both app and framer namespaces
      page_controller: controller,
      page_action: action,
      page_title: document.title,
      page_url: loc.href,
      page_path: loc.pathname,
      page_search: loc.search,
      page_referrer: document.referrer,
    };
  };

  mixpanel.init(process.env.MIXPANEL_PROJECT_TOKEN, { debug: enableDebugMode, persistence: 'localStorage' });
  mixpanel.register(getDefaultProps()); // This ensures above properties are included with every tracked event

  if (typeof window !== 'undefined') {
    window.mixpanel = mixpanel;
  }
};
