import { track } from '~/globals/external-tracking/track';

const bindSearchTracking = () => {
  const navSearchForm = document.querySelector('[data-nav-v2-search-form]');
  const searchPageForm = document.querySelector('.js-search-input-form');
  const formsToTrack = [navSearchForm, searchPageForm];

  formsToTrack.forEach((form) => {
    if (!form) return;

    form.addEventListener('submit', (formEl) => {
      const searchInput = formEl.currentTarget.querySelector('input');
      const searchQuery = searchInput ? searchInput.value.trim() : null;

      if (searchQuery) {
        track('ShotSearched', {
          query: searchQuery,
        });
      }
    });
  });
};

const bindClickEventTracking = () => {
  document.addEventListener('click', (e) => {
    const trackingElement = e.target.closest('[data-track-cta]');
    if (!trackingElement) return;

    const properties = {
      text: trackingElement.getAttribute('data-track-cta') || trackingElement.textContent.trim(),
      location: trackingElement.closest('[data-track-location]')?.getAttribute('data-track-location'),
    };

    track('CallToActionClicked', properties);
  });
};

export const bindListeners = () => {
  bindSearchTracking();
  bindClickEventTracking();
};
