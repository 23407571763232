import { bindListeners } from '~/globals/external-tracking/bind-listeners';
import { init } from '~/globals/external-tracking/init';
import { track, identify } from '~/globals/external-tracking/track';

export const ExternalTracking = {
  init,
  bindListeners,
  track,
  identify,
};
