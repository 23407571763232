/* eslint-disable import/no-extraneous-dependencies */
import mixpanel from 'mixpanel-browser';
import { init } from '~/globals/external-tracking/init';
import { getCookie } from '~/shared/modules/cookies';

// Default payloads below include required values for each event and should always match our tracking plan. Refer to tracking docs for more info.
const defaultPayloads = {
  AuthenticationViewed: {
    object_type: 'Page',
    trigger: 'unknown',
    type: 'Sign Up',
  },
  SignedIn: {
    method: 'email',
  },
  CheckoutViewed: {
    product_name: 'Default Product',
    currency: 'USD',
  },
  CheckoutCompleted: {
    product_price_paid: 'Default Price',
    product_name: 'Default Product',
    currency: 'USD',
  },
  DesignerSearched: {
    search_has_subscription: false,
  },
  JobApplied: {
    job_apply_link: 'Default Link',
    job_company: 'Default Company',
    job_designer_type: 'Default Type',
    job_has_photo: false,
    job_id: 'Default ID',
    job_location_type: 'Default Location Type',
    job_title: 'Default Title',
  },
  JobViewed: {
    job_apply_link: 'Default Link',
    job_company: 'Default Company',
    job_designer_type: 'Default Type',
    job_has_photo: false,
    job_id: 'Default ID',
    job_location_type: 'Default Location Type',
    job_title: 'Default Title',
  },
  JobSearched: {
    freelance: false,
    full_time: false,
  },
  IntroVideoDisplayed: {
    video_location: 'profile',
  },
  IntroVideoDismissed: {
    video_location: 'profile',
    variant: 'mini-card',
  },
  IntroVideoCallToActionClicked: {
    video_location: 'profile',
    cta_text: 'message',
  },
  IntroVideoExpanded: {
    video_location: 'profile',
  },
  PurchaseCallToActionClicked: {
    location: 'Main Body',
    text: 'Default Text',
    currency: 'USD',
    product_name: 'Default Product',
  },
  ShotFeedFiltered: {
    category: '',
  },
  ShotSearched: {
    query: 'Default Query',
  },
  HeaderNavigationClicked: {
    category: 'Default Category',
    item: 'Default Item',
  },
  CallToActionClicked: {
    location: 'Main Body',
    text: 'Default Text',
  },
  UploadCallToActionClicked: {
    location: 'Main Body',
    text: 'Default Text',
  },
  WorkAvailabilityUpdated: {
    work_history_count: 0,
    education_count: 0,
    is_freelance: false,
    is_fulltime: false,
    is_available: false,
  },
  UserMessaged: {
    message_body: 'Default Message Body',
    message_id: 'Default Message ID',
    message_recipient_id: 'Default Message Recipient ID',
    message_thread_id: 'Default Message Thread ID',
  },
  AffiliateLinkClicked: {
    ad_id: 'Default ID',
    ad_link: 'Default Link',
    has_cta: true,
    ad_text: 'Default Ad Text',
    cta_text: 'Default CTA Text',
    owner: 'Default Owner',
  },
  ModalDisplayed: {
    modal_id: 'Default ID',
  },
  ModalDismissed: {
    modal_id: 'Default ID',
  },
  RetainedSubscription: {
    product_name: 'Default Product',
  },
  AnnouncementDisplayed: {
    announcement_id: 'Default ID',
  },
  AnnouncementDismissed: {
    announcement_id: 'Default ID',
  },
  DesignerBookmarked: {
    bookmarked_user: 'Default User',
  },
};

// This method ensures events are fired with their default values if those values are omitted from the payload.
// If values are included in the payload, the default values will not be used.

const getPayloadWithDefaults = (eventName, payload = {}) => {
  if (!defaultPayloads[eventName]) return payload;

  const filteredPayload = Object.keys(payload).reduce((acc, key) => {
    // If payload property is empty string, undefined or null, filter it out so it can be replaced with default value
    if (!payload[key] && payload[key] !== false && payload[key] !== 0) return acc;

    acc[key] = payload[key];
    return acc;
  }, {});

  const defaultPayload = defaultPayloads[eventName];

  const finalPayload = {
    anonymous_id: getCookie('anonymous_id'), // TODO must match name in `CurrentRequest::ANONYMOUS_ID_COOKIE`
    ...defaultPayload,
    ...filteredPayload,
  };

  return finalPayload;
};

// Get global singleton mixpanel instance from window (with fallback to local instance just in case e.g. SSR)
const mixpanelInstance = () => {
  const instance = typeof window === 'undefined' || !window.mixpanel ? mixpanel : window.mixpanel;

  // eslint-disable-next-line no-underscore-dangle
  if (!instance.__loaded) {
    init();
  }

  return instance;
};

export const track = (eventName, payload, options = {}) => {
  const isValidEvent = Object.hasOwn(defaultPayloads, eventName);

  if (!isValidEvent) {
    console.warn(`${eventName} is not a valid event.`);
    return;
  }

  mixpanelInstance().track(eventName, getPayloadWithDefaults(eventName, payload), {
    send_immediately: true,
    ...options,
  });
};

export const identify = () => {
  if (!Dribbble?.JsConfig?.user?.isLoggedIn || !Dribbble?.JsConfig?.user?.id) return;
  mixpanelInstance().identify(Dribbble.JsConfig.user.id);
};
